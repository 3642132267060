import React from "react";
import { Route, Switch } from "react-router-dom";
import Commission from "../modules/Landing/pages/commission";
import LandingMain from "../modules/Landing/pages/index";
import ContactsPage from "../modules/Contacts/pages/ContactsPage";
import { APP_ROUTES } from "./constants/constantsUrl";
import Terms from "../modules/Landing/pages/Terms";
import PostbackOptions from "../modules/Landing/pages/PostbackOptions";
import Testimonials from "../modules/Testimonials/pages/Testimonials";
import NewsList from "../modules/News/pages/NewsList";
import { NewsContent } from "../modules/News/pages/NewsContent";
import TournamentsList from "../modules/Tournaments/pages/TournamentsList";
import { TournamentShow } from "../modules/Tournaments/pages/TournamentShow";
import {
  Revshare,
  SubAffiliate,
  CPA,
} from "../modules/Landing/pages/commissions";
import Faq from "../modules/Faq/pages/Faq";

export default (
  <Switch>
    <Route path="/commission/revshare" component={Revshare} />
    <Route path="/commission/cpa" component={CPA} />
    <Route path="/commission/subaffiliate" component={SubAffiliate} />
    <Route path="/commission" component={Commission} />
    <Route path="/contacts" component={ContactsPage} />
    <Route path={APP_ROUTES.terms} component={Terms} />
    <Route path={APP_ROUTES.postback_options} component={PostbackOptions} />
    <Route exact path={APP_ROUTES.testimonials} component={Testimonials} />
    <Route path={APP_ROUTES.news.list} component={NewsList} />
    <Route path={APP_ROUTES.news.show} component={NewsContent} />
    <Route path={APP_ROUTES.tournaments.list} component={TournamentsList} />
    <Route path={APP_ROUTES.tournaments.show} component={TournamentShow} />
    <Route path={APP_ROUTES.faq.index} component={Faq} />
    <Route path="*" component={LandingMain} />
  </Switch>
);
