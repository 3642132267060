import React from "react";
import config, {ADDITIONAL_CURRENCY_SYMBOL} from "../../../system/config";

export const currencyFormator = new Intl.NumberFormat("ru-RU", {
  useGrouping: true,
});

export function CurrencyLabel({
                                symbol = ADDITIONAL_CURRENCY_SYMBOL,
                                value,
                                pure = false,
                              }) {
  let numVal = parseFloat(value || 0);

  if (!pure) {
    numVal = numVal / 100;
  }

  return (
    <span style={{whiteSpace: "nowrap"}}>
      <span className={value >= 0 ? "currency-green" : "currency-red"}>
        {symbol}
      </span>{" "}
      {currencyFormator.format(numVal)}
    </span>
  );
}
