import React, { useEffect } from "react";
import Header from "../Landing/components/Header";
import Footer from "../Landing/components/Footer";
import MainBannerSlider from "../Landing/components/MainBannerSlider";
import SocialContacts from "../Landing/components/SocialContacts";

const LandingTemplate = (props) => {
  const { children, wrapClass, mainSlider } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="landing-pages">
      <Header />
      {mainSlider && <MainBannerSlider />}
      <div className={`main-wrap ${wrapClass}`}>{children}</div>
      <SocialContacts />
      <Footer />
    </div>
  );
};

export default LandingTemplate;
